// Required dependencies
//import 'jquery';
//import 'popper.js';
//import 'bootstrap';
//import 'lazysizes';

// Assets
// These assets are extracted to [id].bundle.css using MiniCssExtractPlugin, see more on webpack.config.js
//import 'jquery/dist/jquery.js';
//import 'popper.js/dist/popper.js';
//import 'bootstrap/dist/js/bootstrap.js';
//import 'bootstrap/dist/js/bootstrap.bundle.js';

import './puzzle-style/scss/style.scss';

import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.min';

// Application
//import './js/naja';
import './front-style/js/index';

